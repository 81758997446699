<ng-container *ngIf="isOpen">
  <div class="actionModalContainer">
    <div class="actionModalContainerInner {{animationName}}">
      <div class="actionModalContainerHeader">
        <!--        <h2 class="pageTitle">{{modalData.title}}</h2>-->
        <div class="actionModalContainerHeaderClose" (click)="closeModal(null)">
          <mat-icon>close</mat-icon>
        </div>
      </div>
      <div class="actionModalContainerBody">
        <ng-container *ngIf="actionModalData">
          <h1 class="pageTitle actionModalTitle">{{actionModalData?.title | translate}}</h1>
          <p>{{actionModalData?.text | translate}}</p>
        </ng-container>
        <div class="actionModalContainerBodyAction">
          <a (click)="closeModal(false)" class="cancel">{{'cancel' | translate}}</a>
          <button mat-raised-button type="button" (click)="closeModal(true)" customButton class="mainButton " color="warn">{{'yes' |
            translate}}</button>

        </div>
      </div>
    </div>
  </div>
  <div class="actionModalContainerBackground" (click)="closeModal(null)"></div>
</ng-container>