import { NgIf, NgStyle } from "@angular/common";
import {
  AfterContentInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { MatIcon } from "@angular/material/icon";

import { ModalComponentTypes } from "../../enums";
import { ModalData } from "../../interfaces";
import { ModalService } from "../../services/helpers/modal.service";
import { MainService } from "../../services/main.service";

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    standalone: true,
    imports: [NgIf, NgStyle, MatIcon]
})
export class ModalComponent implements OnInit, AfterContentInit{
  @ViewChild('modalBodyContainer', { static: false }) modalBodyContainer: ElementRef;
  constructor(public modalService: ModalService,
              private resolver: ComponentFactoryResolver,
              private renderer2: Renderer2,
              private viewContainerRef: ViewContainerRef,
              public mainService: MainService) {
  }

  // dialogData: OverlapData;
  // componentTypes = DialogComponentTypes;
  // svgTypes = SvgTypes;
  animationName = '';
  modalComponentTypes = ModalComponentTypes;
  modalData: ModalData | undefined;
  childComponentInit = false;
  ngOnInit(): void {
    let sub = this.modalService.modalServiceSubject.subscribe((res: {opened: boolean, data?: ModalData}) => {
      if(res) {
        if(res.opened) {
          this.modalData = res.data;
          setTimeout(() => {
            this.createComponent();
          });
          this.setOpenAnimation();
          this.mainService.stopBodyScrolling();
        }
      }
    });
  }

  ngAfterContentInit() {
    //this.createComponent();
  }

  public closeModal(data?: any): void {
    this.setCloseAnimation();
    setTimeout(() => {
      this.modalService.closeModal(data);
      this.mainService.enableBodyScrolling();
      this.modalData = undefined;
    }, 210);
  }

  public setOpenAnimation(): void {
    this.animationName = 'slide-in-top';
  }

  public setCloseAnimation(): void {
    this.animationName = 'slide-out-bottom';
  }


  public createComponent(): void {
    if(this.modalData.component) {
      const factory = this.resolver.resolveComponentFactory(this.modalData.component);
      const componentRef = this.viewContainerRef.createComponent(factory);
      const componentElement = componentRef.location.nativeElement;
      this.modalBodyContainer.nativeElement.insertBefore(componentElement, this.modalBodyContainer.nativeElement.firstChild);
      setTimeout(() => {
        //@ts-ignore
        let outputSub = componentRef.instance.closed.subscribe(event => {
          this.closeModal(event);
          componentRef.destroy();
          outputSub.unsubscribe();
        });
      })
    }
  }

}
