import { catchError, map, Observable } from "rxjs";

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ResponseMessageStatuses } from "../enums";
import { ResponseObject } from "../interfaces";
import { WarningModalService } from "./helpers/warning-modal.service";
import { LoaderService } from "./loader.service";
import { MessageService } from "./message.service";

@Injectable({ providedIn: "root" })
export class ApiService {
  constructor(
    public http: HttpClient,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private warningModalService: WarningModalService,
  ) {}

  public get<T = ResponseObject>(
    url: string,
    params?: HttpParams,
    responseType: string = null,
  ): Observable<T> {
    const options = { params: params || undefined };
    if (responseType) {
      options["responseType"] = responseType;
    }
    return this.http.get<T>(url, options).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        this.handleException(err);
        throw err;
      }),
    );
  }

  public delete(url: string, params?: HttpParams): Observable<ResponseObject> {
    return this.http.delete(url, { params: params || undefined }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        this.handleException(err);
        throw err;
      }),
    );
  }

  public post<T = ResponseObject>(
    url: string,
    data: unknown,
    responseType,
  ): Observable<T> {
    return this.http
      .post<T>(url, data, responseType ? { responseType: responseType } : {})
      .pipe(
        map((response: T) => {
          return response;
        }),
        catchError((err: Error) => {
          this.handleException(err);
          throw err;
        }),
      );
  }

  public put(url: string, data: any): Observable<ResponseObject> {
    return this.http.put(url, data, {}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        this.handleException(err);
        throw err;
      }),
    );
  }

  public handleException(err: any): void {
    this.loaderService.reset();
    console.log(err);
    const error = err?.error ? err.error : err;
    if (err.status != 401) {
      if (!error?.error_description) {
        try {
          if (error.Message) {
            if ((error.Message.Message || error.Message.StatusMessage) && err.status < 600) {
              // Set some sane defaults
              let title = "An Error has Occured";
              let message = "An unexplained issue has occured."
              
              if (error.Message.Message) {
                // Split any title / message combos
                [title, message] = error.Message.Message.split(" | ", 2);

                // If there was no combo, use the whole message
                if (!message) {
                  message = title;
                }
              }
              
              if (error?.Message?.HandledException) {
                this.warningModalService.openModal({
                  title: title,
                  text: message,
                });
              } else {
                this.messageService.showMessage(
                  message,
                  ResponseMessageStatuses.ERROR,
                );
              }
            }
          } else {
            this.messageService.showMessage(
              this.getValidationErrorText(error),
              ResponseMessageStatuses.ERROR,
            );
          }
        } catch (e) {
          this.messageService.showMessage(
            this.getValidationErrorText(error),
            ResponseMessageStatuses.ERROR,
          );
        }
      }
    }
  }

  public getValidationErrorText(error: any): string {
    let text = error?.title
      ? `${error?.title} \n\n`
      : "Something went wrong. Please try again later";

    if (error?.errors) {
      const errorObject = error?.errors;
      Object.keys(errorObject).forEach((key) => {
        text = `${text} ${key.replace("$.", "")}`;
        const messageArr: string[] = errorObject[key];
        messageArr.forEach((mess) => {
          text = `${text} - ${mess} \n\n`;
        });
      });
    }

    return text;
  }
}
