<ng-container *ngIf="isOpen">
    <div class="warningModalContainer">
        <div class="warningModalContainerInner {{animationName}}">
            <div class="warningModalContainerHeader">
                <!--        <h2 class="pageTitle">{{modalData.title}}</h2>-->
                <div class="warningModalContainerHeaderClose" (click)="closeModal(null)">
                    <mat-icon>close</mat-icon>
                </div>
            </div>
            <div class="warningModalContainerBody">
                <ng-container *ngIf="actionModalData">
                    <h1 class="pageTitle warningModalTitle">{{actionModalData?.title | translate}}</h1>
                    <p>{{actionModalData?.text | translate}}</p>
                </ng-container>
                <div class="warningModalContainerBodyAction">
                    <button mat-raised-button type="button" (click)="closeModal(true)" customButton class="mainButton " color="warn">{{'ok' |
                        translate}}</button>

                </div>
            </div>
        </div>
    </div>
    <div class="warningModalContainerBackground" (click)="closeModal(null)"></div>
</ng-container>