/* eslint-disable @typescript-eslint/no-explicit-any */
import { Subject } from "rxjs";

import { ComponentType } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { ModalComponentTypes } from "../../enums";
import { ModalData } from "../../interfaces";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  modalServiceSubject: Subject<any> = new Subject<any>();
  modalData: ModalData;
  constructor(private translate: TranslateService) {}

  public openModal(
    componentData: any,
    additionalData: any,
    componentType: ModalComponentTypes,
    component: ComponentType<unknown>,
    width?: string,
    displayClass?: string,
  ): Subject<any> {
    this.modalData = this.configModalData(
      componentData,
      additionalData,
      componentType,
      component,
      width,
      displayClass,
    );
    this.modalServiceSubject.next({ opened: true, data: this.modalData });
    this.removeFocusFromAll();
    return this.modalServiceSubject;
  }

  public removeFocusFromAll(): void {
    try {
      const activeElement = document.activeElement as any;
      activeElement.blur();
    } catch (e) {
      //
    }
  }

  public closeModal(data?: any): void {
    this.modalServiceSubject.next({ opened: false, data: data || null });
  }

  public getModalData(): ModalData {
    return this.modalData;
  }

  public configModalData(
    componentData: any,
    additionalData: any,
    componentType: ModalComponentTypes,
    component: ComponentType<unknown>,
    width?: string,
    displayClass?: string,
  ): ModalData {
    return {
      title: this.getModalTitle(
        componentType,
        componentData,
        additionalData ?? undefined,
      ),
      editMode: !!componentData,
      component: component,
      additionalData: additionalData,
      componentData: componentData
        ? JSON.parse(JSON.stringify(componentData))
        : componentData,
      modalComponentType: componentType,
      width: width,
      displayClass: displayClass,
    };
  }

  private getModalTitle(
    componentType: ModalComponentTypes,
    componentData: any,
    additionalData?: any,
  ): string {
    let title = componentData
      ? this.translate.instant("edit")
      : this.translate.instant("new");
    switch (componentType) {
      case ModalComponentTypes.INVENTORY_ITEM:
        title = componentData
          ? this.translate.instant("editInventoryItem")
          : this.translate.instant("addInventoryItem");
        break;
      case ModalComponentTypes.ROLE:
        title = componentData
          ? this.translate.instant("edit")
          : this.translate.instant("add");
        break;
      case ModalComponentTypes.PURCHASE_ORDER_RECEIVE:
        title = componentData
          ? this.translate.instant("edit")
          : this.translate.instant("receive");
        break;
      case ModalComponentTypes.BILL_PAY:
        title = this.translate.instant("billPayment");
        break;
      case ModalComponentTypes.EXPENSE_PAY:
        title = this.translate.instant("expensePayment");
        break;
      case ModalComponentTypes.VENDOR_PAY:
        title = this.translate.instant("vendorPay");
        break;
      case ModalComponentTypes.PO_PAYMENT:
        title = this.translate.instant("payVendorInvoice");
        break;
      case ModalComponentTypes.INVOICE_PAY:
        title = this.translate.instant("payVendorInvoice");
        break;
      case ModalComponentTypes.ACCOUNTS_ADJUST:
        title = this.translate.instant("accountAdjustment");
        break;
      case ModalComponentTypes.ACCOUNTS:
        title = componentData
          ? this.translate.instant("accountUpdateModalTitle")
          : this.translate.instant("accountAddModalTitle");
        break;
      case ModalComponentTypes.INCOME_ADJUSTMENT:
        title = this.translate.instant("incomeAdjustment");
        break;
      case ModalComponentTypes.CUSTOMER:
        title = componentData
          ? this.translate.instant("add")
          : this.translate.instant("addCustomer");
        break;
      case ModalComponentTypes.KP_RECIPE:
        title = componentData
          ? this.translate.instant("editRecipe")
          : this.translate.instant("addRecipe");
        break;
      case ModalComponentTypes.SCHEDULING_SAVE:
        title = this.translate.instant("saveScheduling");
        break;
      case ModalComponentTypes.KP_PRODUCTION:
        title = componentData
          ? this.translate.instant("editProduction")
          : this.translate.instant("addProduction");
        break;
      case ModalComponentTypes.RESET_PASSWORD:
        title = this.translate.instant("accessManagement");
        break;
      case ModalComponentTypes.KP_MOVE:
        title = this.translate.instant("moveToInventory");
        break;
      case ModalComponentTypes.TAX:
        title = componentData
          ? this.translate.instant("editTax")
          : this.translate.instant("addTax");
        break;
      case ModalComponentTypes.CUSTOMER_GROUP:
        title = componentData
          ? this.translate.instant("editCustomerGroup")
          : this.translate.instant("newCustomerGroup");
        break;
      case ModalComponentTypes.POS_LOOKUP_SETTINGS:
        title = componentData
          ? this.translate.instant("editCategory")
          : this.translate.instant("addCategory");
        break;
      case ModalComponentTypes.VENDOR_CATALOG:
        title = this.translate.instant("addFromVendorCatalog");
        break;
      case ModalComponentTypes.VENDOR_CATALOG_RECENT:
        title = this.translate.instant("addFromRecent");
        break;
      case ModalComponentTypes.VENDOR_CATALOG_COMMON:
        title = this.translate.instant("addFromCommon");
        break;
      case ModalComponentTypes.PRICE_CHANGE_ALERT:
        title = this.translate.instant("editPrice");
        break;
      case ModalComponentTypes.RETAIL_PRICE:
        title = this.translate.instant("updateRetailPrice");
        break;
      case ModalComponentTypes.SALE_PRICE:
        title = this.translate.instant("updateSalePrice");
        break;
      case ModalComponentTypes.TEMPLATE_SCHEDULE ||
        ModalComponentTypes.SCHEDULE_EVENT:
        title = componentData
          ? this.translate.instant("editEvent")
          : this.translate.instant("addEvent");
        break;
      case ModalComponentTypes.EMPLOYEE:
        title = componentData
          ? this.translate.instant("editEvent")
          : this.translate.instant("addEmployee");
        break;
      case ModalComponentTypes.MERGE_ITEMS:
        title = this.translate.instant("merge");
        break;
      case ModalComponentTypes.CATEGORY_REPLACE:
        title = this.translate.instant("deleteCategory");
        break;
      case ModalComponentTypes.SCHEDULE_EDIT_SHIFT:
        switch (additionalData?.type) {
          case "schedule":
            title =
              additionalData?.mode === "create"
                ? this.translate.instant("addShift")
                : this.translate.instant("editShift");
            break;
          case "template":
            title =
              additionalData?.mode === "create"
                ? this.translate.instant("addTemplateShift")
                : this.translate.instant("editTemplateShift");
        }
        break;
      case ModalComponentTypes.ESL:
        title = componentData ?
          this.translate.instant("editESLTag") :
          this.translate.instant("addESLTag")
        break;
      case ModalComponentTypes.COST:
        title = this.translate.instant('updateCost');
        break;
      default:
        title = "Unknown Component";
        break;
    }
    return title;
  }
}
