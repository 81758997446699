export enum SvgTypes {
  Inventory = 1,
  Down = 2,
  Invoices = 3,
  Purchase_order = 4,
  Bill = 5,
  Expense = 6,
  Income = 7,
  Customers = 8,
  Employees = 9,
  Kitchen = 10,
  Accounting = 11,
  Reports = 12,
  Settings = 13,
  Notification = 14,
  Search = 15
}

export enum ModalComponentTypes {
  INVENTORY_ITEM = 1,
  MIX_AND_MATCH = 2,
  TRANSFERS = 3,
  IN_STOCK_ADJUSTMENT = 4,
  RETAIL_PRICE = 5,
  SALE_PRICE,
  PURCHASE_ORDER_RECEIVE = 7,
  RETURN_TO_VENDOR = 8,
  VENDOR = 9,
  PO_PAYMENT = 10,
  BRANCH = 11,
  PURCHASE_ORDER_CREATE = 12,
  CATEGORY = 13,
  EMPLOYEE = 14,
  DEPARTMENT = 15,
  ROLE = 16,
  TEMPLATE_SCHEDULING = 17,
  CUSTOMER = 18,
  BILL = 19,
  BILLER = 20,
  BILL_PAY = 21,
  EXPENSE = 22,
  INCOME = 23,
  INCOME_SOURCE = 24,
  ACCOUNTS = 25,
  SCHEDULING_SAVE = 26,
  INVOICE_ACCOUNT_RECEIVABLE = 27,
  KP_RECIPE = 28,
  INVOICE_RECEIVER = 29,
  KP_PRODUCTION = 30,
  SHRINK = 31,
  PAYROLL_DETAILS = 32,
  KP_MOVE = 33,
  RESET_PASSWORD = 34,
  EXPENSE_PAY = 35,
  INVOICE_PAY = 36,
  TAX = 37,
  BALANCE_TRANSFER = 38,
  ACCOUNT_TRANSACTIONS = 39,
  ACCOUNTS_ADJUST = 40,
  COUPON = 41,
  CUSTOMER_GROUP = 42,
  OWNER = 43,
  OWNER_DRAW = 44,
  POS_LOOKUP_SETTINGS = 45,
  PRICE_CHANGE_ALERT = 46,
  END_OF_SHIFT = 47,
  VENDOR_CATALOG = 48,
  VENDOR_PAY = 49,
  CRV = 50,
  TEMPLATE_SCHEDULE = 51,
  SCHEDULE = 52,
  SCHEDULE_EVENT = 53,
  MERGE_ITEMS = 54,
  CLOCK_IN_OUT = 55,
  CATEGORY_REPLACE = 56,
  PRINTERS = 57,
  PRICE_TAG_TEMPLATES = 58,
  VENDOR_CATALOG_RECENT = 59,
  VENDOR_CATALOG_COMMON = 60,
}


export enum GridDetailTypes {
  RETAIL = 1,
  SALE = 2,
  RETURN_TO_VENDOR_DETAILS = 3
}

export enum ProductLookupViewType {
  NAME = 1,
  ITEM_NUMBER = 2
}

export enum SystemCode {
  DRAFT = 'Draft',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  CANCELED = 'Canceled',
  CREATED = 'Created',
  UPDATE_IN_POS = 'UpdatedInPOS',
  UPDATED = 'Updated',
  FIXED = 'Fixed',
  AMOUNT = 'Amount',
  PERCENT = 'Percent',
  NON_OFFER = 'NonOffer',
  DECLINED = 'Declined',
  DELETE ='Delete',
  CREDITED = 'Credited',
  PENDING = 'Pending',
  OPEN = 'Open',
  CASH = 'Cash',
  PAID = 'Paid',
  PartiallyPaid = 'PartiallyPaid',
  CHECK = 'Check',
  CHECKING = 'Checking',
  SAVINGS = 'Savings',
  CC = 'CC',
  ACH = 'ACH',
  CREDITCARD = 'CreditCard',
  BANKACCOUNT = 'BankAccount',
  WEBPAYMENT = 'WebPayment',
  UNCONFIRMED = 'Unconfirmed',
  CONFIRMED = 'Confirmed',
  BRANCH = 'Branch',
  REVIEWED = 'Reviewed',
  IGNORED = 'Ignored',
  APPROVED = 'Approved',
  NO = 'No',
  PreApproval = 'PreApproval',
  YES = 'Yes',
  MIXED = 'Mixed',
  BULK = 'Bulk',
  OWNER = 'Owner',
  BILL = 'Bill',
  BILLS = 'Bills',
  EXPENSE = 'Expenses',
  INVOICES = 'Invoices',
  INCOME = 'Income',
  ACTIVE = 'Active',
  IN_ACTIVE = 'InActive',
  DATE = 'Date',
  DayOfWeek = 'DayOfWeek',
  TIME = 'Time',
  EXPIRED = 'Expired',
  OTHER = 'Other',
  DAMAGED = 'Damaged',
  PARTLY_COMPLETED = 'PartlyCompleted',
  PARTLY_RECEIVED = 'PartiallyReceived',
  RECEIVED = 'Received',
  FUTURE= 'Future',
  CURRENT = 'Current',
  CANCEL = 'Cancel',
  CREDITED_RECEIVED = 'CreditReceived',
  ManagersApproval = 'ManagersApproval',
  RETURNED = 'Returned',
  VendorsApprove = 'VendorsApprove',
  IN_PROGRESS = 'InProgress',
  RETURNED_BACK = 'ReturnedBack',
  Q = 'Q',
  CANCEL_SALE = 'CancelSale',
  EndOfMonth = 'EndOfMonth',
  UponDelivery = 'UponDelivery',
  CreditRecurved = 'CreditRecurved',
  DirectSale = 'DS',
  InternalPurchaseOnly = 'IPO',
  InDirectSale = 'IS',
  LB = 'LB',
  OZ = 'OZ',
  KG = 'KG',
  EACH = 'Each',
  CASE = 'Case',
  WEIGHT = 'Weight',
  NOTFORSALE = 'NFS',
  Discontinued  = 'D',
  STANDARD = 'Standard',
  P18 = 'P18',
  P21 = 'P21',
  SALE = 'Sale',
  RETAIL = 'Retail',
  PENDING_PRICE_TAG = 'PPT',
  PENDING_APPROVAL = 'PA',
  ACTIVE_SALE = 'AS',
  FUTURE_FU = 'FU',
}

export enum TreeTypes {
  BRANCH = 1,
  CATEGORY = 2,
  ORGANIZATIONALSTRUCTURE = 3,
}


export enum WeekDays {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 0
}

export enum UploaderViewType {
  TEXT = 1,
  BUTTON = 2
}

export enum GridStructureNumberType {
  Money = 'money',
  Number = 'number',
  PERCENT = 'percent',
  PHONE = 'phone',
  DATE = 'date',
  Time = 'time'

}

export interface CustomTime {
  value: string;
  value24: string;
}


export enum ResponseMessageStatuses {
  SUCCESS = 1,
  ERROR = 2,
  INFO = 3
}

export enum DeliveredTypes {
  FREIGHT= 1,
  UPS = 2,
  FEDEX = 3,
  USPS = 4,
  PICKUP = 5,
  OTHER = 6
}

export enum DiscrepancyTypes {
  Unknown = 0,
  Counterfit = 1,
  OverCharge = 2,
  UnderCharge = 3,
  CashPickupError = 4,
  VendorPayoutError = 5,
  BreakBillError = 6,
}
