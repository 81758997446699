import {Injectable} from "@angular/core";
import {ApiManagerService} from "./api-manager.service";
import {Observable} from "rxjs";
import {HttpParams} from "@angular/common/http";
import {Constants} from "../constants";
import {ResponseObject} from "../interfaces";

@Injectable({providedIn: 'root'})
export class NetworkService {
  constructor(private apiManager: ApiManagerService) {
  }
  public getGridStructure(url: string, hideLoading?: boolean): Observable<ResponseObject> {
    return this.apiManager.getRequest(url, undefined, hideLoading);
  }

  public getGridFilterData(url: string, hideLoading?: boolean): Observable<ResponseObject> {
    return this.apiManager.getRequest(url, undefined, hideLoading);
  }

  public getAllEnumsConfiguration(hideLoading?: boolean): Observable<any> {
    return this.apiManager.getRequest(Constants.inventoryApi + Constants.inventory.getAllEnumsConfiguration, undefined, hideLoading);
  }

  public lookup(url: string, params: HttpParams, hideLoading?: boolean): Observable<ResponseObject> {
    return this.apiManager.getRequest(url, params, hideLoading);
  }

  public authenticationPlaid(token: string): Observable<ResponseObject> {
    let tok = `"${token}"`;
    return this.apiManager.postRequest(Constants.expensesApi + Constants.expenses.authenticationPlaid, tok);
  }
}
