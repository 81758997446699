import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { LoaderService } from "./shared/services/loader.service";
import { WarningModalComponent } from "./shared/components/warning-modal/warning-modal.component";
import { ActionModalComponent } from "./shared/components/action-modal/action-modal.component";
import { LoaderComponent } from "./shared/components/loader/loader.component";
import { NgIf } from "@angular/common";
import { ModalComponent } from "./shared/components/modal/modal.component";
import { Router, RouterOutlet } from "@angular/router";
import { environment } from "./app.config";

// Third-party extensions
import { NgxEchartsDirective } from "ngx-echarts";
import { register } from "swiper/element/bundle";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AngularPlugin } from "@microsoft/applicationinsights-angularplugin-js";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [
    RouterOutlet,
    ModalComponent,
    NgIf,
    LoaderComponent,
    ActionModalComponent,
    WarningModalComponent,
    NgxEchartsDirective,
  ],
})
export class AppComponent implements OnInit {
  title: string = "UniSight";
  isLoaderActive = true;
  constructor(
    private loaderService: LoaderService,
    private cd: ChangeDetectorRef,
    private router: Router,
  ) {
    this.initializeAppInsights();
  }

  ngOnInit() {
    this.loaderService.loaderSubject.subscribe((value) => {
      this.isLoaderActive = value > 0;
      this.cd.detectChanges();
      register();
    });
  }

  private initializeAppInsights() {
    if (
      environment.appInsightsConnectionString !== undefined &&
      environment.appInsightsConnectionString !== ""
    ) {
      const angularPlugin = new AngularPlugin();
      const clickPluginInstance = new ClickAnalyticsPlugin();
      const clickPluginConfig = {
        autoCapture: true,
        dataTags: {
          useDefaultContentNameOrId: true,
        },
      };
      const appInsights = new ApplicationInsights({
        config: {
          connectionString: environment.appInsightsConnectionString,
          enableAutoRouteTracking: true,
          extensions: [angularPlugin, clickPluginInstance],
          extensionConfig: {
            [angularPlugin.identifier]: { router: this.router },
            [clickPluginInstance.identifier]: clickPluginConfig,
          },
        },
      });
      appInsights.loadAppInsights();
      appInsights.trackPageView();
    }
  }
}
